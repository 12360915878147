import React, { Fragment, useContext, useEffect } from "react"
import { useCustomAxios } from "../../Hooks/useAxios"
import ESpinner from "../../components/ESpinner"
import CharacteristicsModal from "../Dashbaord/Modals/CharacteristicsModal"
import { axiosInstance } from "../../api/requister"
import { MdOutlineCancel } from "react-icons/md"
import { useParams } from "react-router"
import { MyContext } from "../../context/WarningsContenxt"
import { generateKey } from "../../helpers/utils"

const FileDetails = ({withCharacteristic, stickyFileDetails}) => {
  const fileId = useParams().id
  const [fileCharectersitics, setFileCharectersitics] = React.useState([])
  const [isFileCharModalOpen, setIsFileCharModalOpen] = React.useState(false)
  const { data, refetchData, loading } = useContext(MyContext)
  const fileDetailsUpper = useCustomAxios({
    url: `fileDetails/?id=${fileId}`,
    method: 'GET',
  })
  const fileCharacteristics = useCustomAxios({
    url: `fileCharacteristics/?id=${fileId}`,
    method: 'GET',
  })
  useEffect(() => {
    if (fileCharacteristics?.response?.['File Characteristics']) {
        setFileCharectersitics(fileCharacteristics?.response?.['File Characteristics'])
    }
  }, [fileCharacteristics])
  const handleAddFileCharacteristics = (columnNumber) => {
    axiosInstance
      .post(`/fileCharacteristics/`, {
        id: fileId,
        columnNumber: columnNumber,
        'File Characteristics': fileCharectersitics,
      })
      .then((res) => {
        fileCharacteristics.Refetch()
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleRemoveFileCharacteristics = (
    fullObject,
    deletedObject,
    columnNumber,
  ) => {
    const fileDetailsRequestKey = [
      ...fullObject.filter((item) => item.id !== deletedObject.id),
      {
        ...deletedObject,
        status: false,
      },
    ]
    axiosInstance
      .post(`/fileCharacteristics/`, {
        id: fileId,
        columnNumber: columnNumber,
        'File Characteristics': fileDetailsRequestKey,
      })
      .then((res) => {
        fileCharacteristics.Refetch()
      })
      .catch((err) => {
        console.log(err)
      })
  }
    return  <div className={stickyFileDetails ? 'sticky top-16 z-10' : ''}>
    <div className={'bg-gray-50'}>
      <div className={'flex'}>
        {fileDetailsUpper?.loading ? (
          <div className={'flex justify-center items-center w-full'}>
            <ESpinner isVisible={true} />
          </div>
        ) : (
          <Fragment>
            <div className={'flex  border-r border-b'}>
              <div className={'flex flex-col border-r w-8/12 '}>
                <span
                  className={
                    'text-left  px-3  block font-semibold text-lg  border-b '
                  }>
                  File Details
                </span>
                <div className={'flex w-full'}>
                  <div className="border-r w-6/12">
                    <span className="block font-black border-b">Property</span>
                    <div className="grid grid-rows-3 grid-flow-col w-full">
                      {/* {fileDetailsUpper?.response?.result?.Property ? Object.entries(fileDetailsUpper?.response?.result?.Property).map(([key, value]) => (
                        
                      )) : null} */}
                      <div className="w-12/12 ">
                        <div className="w-8/12 inline border">
                          <span className=" font-semibold ">{"Address"}:</span>
                          <span>{fileDetailsUpper?.response?.result?.Property?.Address.value}</span>
                        </div>
                        <div className="w-4/12 inline border">
                          <span className=" font-semibold ">{"Block/UPN"}:</span>
                          {fileDetailsUpper?.response?.result?.Property?.['Block/UPN'] ? (
                            <span>{fileDetailsUpper?.response?.result?.Property?.['Block/UPN'].value}</span>
                          ) : (
                            <span>{fileDetailsUpper?.response?.result?.Property?.['Block'].value}</span>
                          )}
                          
                        </div>
                      </div>
                      <div className="w-12/12 ">
                        <div className="w-8/12 inline border">
                          <span className=" font-semibold ">{"County"}:</span>
                          <span>{fileDetailsUpper?.response?.result?.Property?.County.value}</span>
                        </div>
                        <div className="w-4/12 inline border">
                          <span className=" font-semibold ">{"Lot"}:</span>
                          <span>{fileDetailsUpper?.response?.result?.Property?.Lot.value}</span>
                        </div>
                      </div>
                      <div className="w-12/12 ">
                        <div className="w-8/12 inline border">
                          <span className=" font-semibold ">{"Municipality"}:</span>
                          <span>{fileDetailsUpper?.response?.result?.Property?.Municipality.value}</span>
                        </div>
                        <div className="w-4/12 inline border">
                          <span className=" font-semibold ">{"Qual"}:</span>
                          <span>{fileDetailsUpper?.response?.result?.Property?.Qual.value}</span>
                        </div>
                      </div>
                  </div>
                  </div>
                  <div className="border-r w-4/12">
                    <span className="block font-black border-b">Parties</span>
                    <div className="">
                    {fileDetailsUpper?.response?.result?.Parties?.map((generalItem, index) => (
                      <div key={index} className={'flex border-b items-end'}>
                        <span className="block font-semibold pr-5">{generalItem.label}:</span>
                        <span>{generalItem.value}</span>
                      </div>
                    ))}
                    </div>
                  </div>
                  <div className="w-2/12">
                    <span className="block font-black border-b">VDeed</span>
                    <div className="">
                      {fileDetailsUpper?.response?.result?.VDeed?.map((vDeed, index) => (
                        <div key={index} className={'grid grid-cols-2 border-b'}>
                          <span className="block font-semibold">{vDeed.label}:</span>
                          <span>{vDeed.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>
              {withCharacteristic && (
              <div className={'w-3/12 border-r'}>
                    <div
                        className={
                        'flex items-center gap-1 border-b p-1 justify-between'
                        }>
                        <span
                        className={
                            'text-left whitespace-nowrap block font-semibold text-lg'
                        }>
                        File characteristic
                        </span>
                        <button
                        onClick={() => setIsFileCharModalOpen(true)}
                        disabled={fileCharacteristics?.loading}
                        className={
                            'flex gap-1  items-center border my-1 bg-gray-100 hover:bg-gray-200 border-gray-400 rounded px-1'
                        }>
                        <small className={'font-semibold'}>Manage</small>
                        </button>
                    </div>
                    <div
                        style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        }}
                        className={'flex flex-wrap gap-1 p-4'}>
                        {fileCharacteristics?.loading ? (
                        <div className={'flex justify-center items-center'}>
                            <ESpinner isVisible={true} />
                        </div>
                        ) : (
                            <Fragment>
                        {fileCharacteristics?.response?.[
                            'File Characteristics'
                        ]?.map((item) => {
                            return (
                            item.status === true && (
                                <div
                                key={item.id}
                                className={
                                    'text-xs px-2 py-1 flex gap-1 items-center bg-white border rounded-full'
                                }>
                                <span>{item.name}</span>
                                <MdOutlineCancel
                                    onClick={() =>
                                    handleRemoveFileCharacteristics(
                                        fileCharacteristics?.response?.[
                                        'File Characteristics'
                                        ],
                                        item,
                                        fileCharacteristics?.response?.columnNumber,
                                    )
                                    }
                                    size={15}
                                    style={{ minWidth: '20px' }}
                                    className={
                                    'text-gray-300 hover:text-red-600 cursor-pointer'
                                    }
                                />
                                </div>
                            )
                            )
                        })}
                        <CharacteristicsModal
                            fileCharacteristics={fileCharacteristics}
                            handleAddFileCharacteristics={handleAddFileCharacteristics}
                            isFileCharModalOpen={isFileCharModalOpen}
                            setFileCharectersitics={setFileCharectersitics}
                            setIsFileCharModalOpen={setIsFileCharModalOpen}
                            fileCharectersitics={fileCharectersitics}
                        />
                            </Fragment>
                        )}
                    </div>
                </div>
              )}
                <div className={'w-1/12 '}>
                    <span
                        className={
                            'text-left block font-semibold px-3 py-1 text-lg  border-b '
                        }>
                        Attributes
                    </span>
                    {loading ?  <ESpinner isVisible={true} /> : data?.attributes?.map(item => <span key={generateKey()} className="bg-light border">{item}</span>)}
                    <span
                        className={
                            'text-left block font-semibold px-3 py-1 text-lg  border-b border-t'
                        }>
                        Warnings
                    </span>
                    {loading ?  <ESpinner isVisible={true} /> : data?.warnings?.map(item => <span key={generateKey()} className="bg-light border">{item}</span>)}
                </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>

  </div>
}
export default FileDetails