import logo from '../../assets/images/logo.png'
import React from 'react'

const Footer = () => {
  return (
    <footer className="footer mt-auto py-5 px-3 flex  items-end justify-between border-t border-gray-300">
      <span className={'text-xs text-gray-400'}>V.1.2.5</span>
      <img
        src={logo}
        className={'opacity-40 grayscale'}
        width={80}
        alt={'logo'}
      />
      <span className={'text-xs text-gray-400'}>Contact us</span>
    </footer>
  )
}
export default Footer
