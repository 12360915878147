import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useCustomAxios } from '../../Hooks/useAxios'
import { axiosInstance } from '../../api/requister'
import ESpinner from '../../components/ESpinner'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import {axiosFileInstance} from "../../api/fileRequester"
import DefaultLayout from '../Layout/DefaultLayout'
import DataTable from './DataTable'
import RowModal from './Modals/RowModal'
import { MyContext } from '../../context/WarningsContenxt'

const Dashboard = () => {
  const fileId = useParams().id
  const [filter, setFilter] = React.useState('')
  const [GPTmockData, setGPTmockData] = React.useState({})
  const [activeRow, setActiveRow] = useState('')
  const docNames = useCustomAxios({
    url: `docNames/?id=${fileId}`,
    method: 'GET',
  })

  const SheetMetaData = useCustomAxios({
    url: `sheetName/?id=${fileId}`,
    method: 'GET',
  })

  const fileDetails = useCustomAxios({
    url: `chainOfTitle/?id=${fileId}${filter ? '&filter=' + filter : ''}`,
    method: 'GET',
  })




  useEffect(() => {
    fileDetails?.Refetch()
  }, [filter])

  useEffect(() => {
    if (fileDetails?.response) {
      if (activeRow && fileDetails.response[activeRow] === undefined) {
        setActiveRow(activeRow + 1)
      }
      setGPTmockData(fileDetails?.response)
    }
  }, [fileDetails])

  const updateGPTmockData = (key, columnName, newValue) => {
    setActiveRow(key)
    setGPTmockData((prevGPTmockData) => {
      const updatedData = { ...prevGPTmockData }
      updatedData[key].values = updatedData[key].values.map((item) => {
        if (item.columnName === columnName) {
          return { ...item, columnValue: newValue }
        }
        return item
      })
      return updatedData
    })
  }
  const { refetchData } = useContext(MyContext)
  const handleUpdate = (key, columnNumber, value) => {
    axiosInstance
      .post(`document/chainOfTitleCell/`, {
        id: fileId,
        columnNumber: columnNumber,
        rowNumber: key,
        value: value,
      })
      .then((res) => {
        console.log(res)
        if (parseInt(columnNumber) === 3 || parseInt(columnNumber) === 4 || parseInt(columnNumber) === 9 || parseInt(columnNumber) === 10 ) {
          refetchData()
        }
        if (parseInt(columnNumber) === 1 || parseInt(columnNumber) === 2 || parseInt(columnNumber) === 3) {

          setActiveRow(key)
          fileDetails?.Refetch()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }





    const [showFileModal, setShowFileModal] = React.useState(false)
    const [selectFileRecord, setSelectedFileRecord] = React.useState({})
    const [requesting, setRequesting] = React.useState({})
    const [isRequesting, setIsRequesting] = React.useState(false)
    useEffect(() => {
      setIsRequesting(Object.keys(requesting).some(request => requesting[request]))
    }, [requesting])

    const [chainOfTitles, setChainOfTitles] = React.useState([])
    const [childDocs, setChildDocs] = React.useState([])
    const [docCharacterstics, setDocCharacterstics] = React.useState([])

    const [legalData, setLegalData] = React.useState({})
    const [legalInfo, setLegalInfo] = React.useState({})
    const [legalId, setLegalId] = React.useState('')

    const [filedMapData, setFiledMapData] = React.useState([])


    const handelChainOfTitles = (columnNumber, newValue) => {
    setChainOfTitles(
      chainOfTitles.map((item) => {
        if (item.columnNumber === columnNumber) {
          item.columnValue = newValue
        }
        return item
      })
    )
  }
    const handelLegalData = (key, newValue, columnNumber, rowNumber) => {
    if (Array.isArray(legalData[key])) {
      setLegalData({
          ...legalData,
          [key]: legalData[key].map(item => {
              if (columnNumber === item.columnNumber && rowNumber === item.rowNumber) {
                item.value = newValue
              }
              return item
          })
      })
    } else {
    setLegalData({
      ...legalData,
      [key]: {...legalData[key], value: newValue}
    })
    }
    }
    const handelLegalInfo = (key, newValue, columnNumber, rowNumber) => {
        if (Array.isArray(legalData[key])) {
            setLegalInfo({
                ...legalInfo,
                [key]: legalInfo[key].map(item => {
                    if (columnNumber === item.columnNumber && rowNumber === item.rowNumber) {
                        item.value = newValue
                    }
                    return item
                })
            })
        } else {
            setLegalInfo({
                ...legalInfo,
                [key]: {...legalInfo[key], value: newValue}
            })
        }
    }
    const handelFiledMapData = (rowNumber, newValue) => {
      setFiledMapData(
        filedMapData.map((item) => {
          if (item.rowNumber === rowNumber) {
            item.value = newValue
          }
          return item
        })
      )
    }

    const saveLegalData = (data) => {
    axiosInstance
      .post(`/legal/data/`, {
        id: fileId,
        rowNumber: data['rowNumber'],
        columnNumber: data['columnNumber'],
        value: data['value'],
        legalId: legalId
      })
      .then((res) => {
        toast('Legal Data Updated', {
          position: 'top-right',
          type: 'success',
        })
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
    }
    const uploadLegalData = (data) => {
    const formData = new FormData()
    formData.append('id', fileId)
    formData.append('rowNumber', data['rowNumber'])
    formData.append('columnNumber', data['columnNumber'])
    formData.append('value', data['value'])
        formData.append('legalId',  legalId)
    axiosFileInstance
    .post(`/legal/data/image/`,formData)
    .then((res) => {
      toast('Image Uploaded &  Legal Data Updated', {
        position: 'top-right',
        type: 'success',
      })
      console.log(res)
    })
    .catch((err) => {
      console.log(err)
    })
    }
    const saveChainOfTitleCell = (data) => {
        axiosInstance
            .post(`/document/chainOfTitleCell/`, {
                id: fileId,
                rowNumber: data['rowNumber'],
                columnNumber: data['columnNumber'],
                value: data['columnValue'],
            })
            .then((res) => {
                toast('Chain of Title Cell Updated', {
                    position: 'top-right',
                    type: 'success',
                })
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const saveDocCharacterstics = (chareteristic) => {
        setDocCharacterstics((prevDocCharectersitics) => {
            const updatedData = [...prevDocCharectersitics]
            updatedData[
                updatedData.findIndex((item) => item.id === chareteristic.id)
                ].status = !chareteristic.status
            return updatedData
        })
        axiosInstance
            .post(`/document/characterstics/`, {
                id: fileId,
                rowNumber: selectFileRecord.key,
                characteristic: { id: chareteristic.id, status: chareteristic.status },
            })
            .then((res) => {
                toast('Document Characterstics  Updated', {
                    position: 'top-right',
                    type: 'success',
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const saveFieldMapData = (data) => {
      axiosInstance
          .post(`/filedMap/`, {
              id: fileId,
              rowNumber: data['rowNumber'],
              columnNumber: data['columnNumber'],
              value: data['columnValue'],
          })
          .then((res) => {
              toast('Filed Map Updated', {
                  position: 'top-right',
                  type: 'success',
              })
              console.log(res)
          })
          .catch((err) => {
              console.log(err)
          })
  }
    /*
    * handel Modal
    * */
  const toggleFileModal = (record = null) => {
        if (record) {
          console.log(record)
            setSelectedFileRecord(record)
        }
        setShowFileModal(!showFileModal)
    }
  const loadModalData = () => {
    const rowNumber = selectFileRecord.key
    setRequesting({
      titleRowsRequest: true,
      characteristicsRequest: true,
      childDocsRequest: true,
      legalDataRequest: selectFileRecord.cell === "VESTING DEED",
      legalInfoRequest: selectFileRecord.cell === "VESTING DEED",
      filedMapRequest: selectFileRecord.cell === "Filed Map"
    })
    axiosInstance
        .get(`/document/chainOfTitleRow?rowNumber=${rowNumber}&id=${fileId}`)
        .then((res) => {
          setChainOfTitles(
              res.data.columns.map((item) => ({
                  ...item,
                  rowNumber: res.data.row_number,
              })),
          )
          setRequesting(prevState => ({...prevState, titleRowsRequest: false}))
        })
        .catch((err) => {
          console.log(err)
          setRequesting(prevState => ({...prevState, titleRowsRequest: false}))
      })
    axiosInstance
        .get(`/document/characterstics?rowNumber=${rowNumber}&id=${fileId}`)
        .then((charRes) => {
            setDocCharacterstics(charRes.data)
            setRequesting(prevState => ({...prevState, characteristicsRequest: false}))
        })
        .catch((err) => {
            setRequesting(prevState => ({...prevState, characteristicsRequest: false}))
            console.log(err)
        })
    axiosInstance
        .get(`/document/childDocs?rowNumber=${rowNumber}&id=${fileId}`)
        .then((childDocsRes) => {
            setChildDocs(childDocsRes.data)
            setRequesting(prevState => ({...prevState, childDocsRequest: false}))
        })
        .catch((err) => {
            setRequesting(prevState => ({...prevState, childDocsRequest: false}))
            console.log(err)
        })
      if (selectFileRecord.cell === "VESTING DEED") {
        axiosInstance
            .get(`/legal/data?id=${fileId}&rowNumber=${rowNumber}`)
            .then(LegalDataRes => {
                setLegalData(LegalDataRes.data)
                setLegalId(LegalDataRes.data.legalSheetId)
                setRequesting(prevState => ({...prevState, legalDataRequest: false}))
            })
            .catch(err => {
                setRequesting(prevState => ({...prevState, legalDataRequest: false}))
                console.log(err)
            })
        axiosInstance
            .get(`/legal/info?id=${fileId}&rowNumber=${rowNumber}`)
            .then(LegalInfoRes => {
                setLegalInfo(LegalInfoRes.data)
                setRequesting(prevState => ({...prevState, legalInfoRequest: false}))
            })
            .catch(err => {
                setRequesting(prevState => ({...prevState, legalInfoRequest: false}))
                console.log(err)
            })
      } 
      
      if (selectFileRecord.cell === "Filed Map") {
          axiosInstance
              .get(`/filedMap?id=${fileId}`)
              .then(filedMapRes => {
                  setFiledMapData(filedMapRes.data)
                  setRequesting({...requesting, filedMapRequest: false})
                })
              .catch(err => {
                setRequesting({...requesting, filedMapRequest: false})
                console.log(err)
              })
      }
  }
  useEffect(() => {
      if (showFileModal === true && selectFileRecord && selectFileRecord.key) {
          loadModalData()
      } else {
          setSelectedFileRecord({})
          setChainOfTitles([])
          setChildDocs([])
          setDocCharacterstics([])
          setLegalData({})
          setLegalInfo({})
      }
  }, [showFileModal])
  const chainOfTitleFilters = useCustomAxios({
    url: `filters/?id=${fileId}`,
    method: 'GET',
  })

  return (
    <DefaultLayout withButtons withFileDetails withCharacteristic stickyFileDetails>
      <div className='relative'>
        
      <div className='bg-gray-50'>
      {chainOfTitleFilters?.loading ? (
        <div className={'flex justify-center items-center w-full'}>
          <ESpinner isVisible={true} />
        </div>
      ) : (
        <div className={'p-3'}>
          <select
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value)
            }}
            className={'w-full h-9 rounded border border-gray-300'}>
            <option value="">Select Filter</option>
            {chainOfTitleFilters?.response?.['Filters']?.map((item) => (
              <option key={item.id} value={item.Filter}>
                {item.Filter}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
      {fileDetails?.loading ? (
        <div className={'flex justify-center items-center w-full'}>
          <ESpinner isVisible={true} />
        </div>
      ) : (
        <Fragment>
          <DataTable
            GPTmockData={GPTmockData}
            handleUpdate={handleUpdate}
            updateGPTmockData={updateGPTmockData}
            docNames={docNames}
            toggleFileModal={toggleFileModal}
            activeRow={activeRow}
          />
        </Fragment>
      )}
      
      <RowModal
        selectFileRecord={selectFileRecord}
        showFileModal={showFileModal}
        setShowFileModal={setShowFileModal}
        isRequesting={isRequesting}
        chainOfTitles={chainOfTitles}
        saveChainOfTitleCell={saveChainOfTitleCell}
        handelChainOfTitles={handelChainOfTitles}
        docCharacterstics={docCharacterstics}
        saveDocCharacterstics={saveDocCharacterstics}
        childDocs={childDocs}
        toggleFileModal={toggleFileModal}
        legalData={legalData}
        legalInfo={legalInfo}
        saveLegalData={saveLegalData}
        handelLegalData={handelLegalData}
        uploadLegalData={uploadLegalData}
        handelLegalInfo={handelLegalInfo}
        saveFieldMapData={saveFieldMapData}
        filedMapData={filedMapData}
        handelFiledMapData={handelFiledMapData}
      />
</div>
    </DefaultLayout>
  )
}
export default Dashboard
