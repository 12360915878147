import React, { Fragment } from 'react'
import { BsListUl } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import SectionTitle from '../../components/SectionTitle'
import { useCustomAxios } from '../../Hooks/useAxios'
import DefaultLayout from '../Layout/DefaultLayout'
import ESpinner from '../../components/ESpinner'
import FCollapse from '../../components/FCollapse'
import { generateKey } from '../../helpers/utils'
const Esnars = () => {
  const { id } = useParams()
  const fileMeta = useCustomAxios({
    method: 'GET',
    url: `https://titlereader-backend.hughjoseph.com/title?id=${id}`,
  })

  const esAndRs = useCustomAxios({
    method: 'GET',
    url: `util/esAndRs?id=${id}`,
  })

  const legalInfo = useCustomAxios({
    method: 'GET',
    url: `/legal/info/all?id=${id}`,
  })
  if (esAndRs?.loading || fileMeta.loading) {
    return <div className={'flex justify-center items-center w-full'}>
    <ESpinner isVisible={true} />
  </div>
  } else
    return (
      <DefaultLayout>
      <div className={'flex flex-col items-center justify-center'}>
        <div className={'f-col-center-center h-full w-full mt-10    '}>
          <div className={'text-sm text-gray-600 text-left block w-9/12 my-3 '}>
            <SectionTitle esAndRs={esAndRs} fileID={id} title={'Title Vested Exceptions and Requirements'} />
            <div className={'w-full grid grid-cols-3 gap-3    '}>
              {Object.keys(esAndRs?.response ?? []).map((key, index) => {
                return (
                  <Link
                    to={`/detail/${id}/${key}`}
                    key={index}
                    className={
                      'flex flex-col border p-8 hover:bg-gray-50 cursor-pointer primary-shadow bg-white rounded shadow'
                    }>
                    <span className={'text-gray-700 text-2xl font-semibold'}>
                      <BsListUl />
                      {key}
                    </span>
                    <p
                      className={
                        'text-sm border border-blue-700  text-blue-600  mt-3 w-fit px-3 rounded-full bg-blue-100'
                      }>
                      {Object.values(esAndRs?.response[key]).length} entries
                    </p>
                  </Link>
                )
              })}
            </div>
            {legalInfo && legalInfo.response && (
            <Fragment>
                <Fragment>
                  {legalInfo.response.legals.length && legalInfo.response.legals.map(item => (
                    <div key={generateKey()}>
                      <hr style={{margin: "16px 0 "}}/>
                        <div className={'grid grid-cols-1 bg-white border  p-50'}>
                            <h4 className='text-xl text-dark font-bold'>{item.key}</h4>
                            <span dangerouslySetInnerHTML={{__html: item.data.replaceAll("\n", "<br/>")}}></span>
                        </div>
                    </div>
                  ))}
                </Fragment>
            </Fragment>
          )}
          </div>

        </div>
      </div>
      </DefaultLayout>
    )
}

export default Esnars
