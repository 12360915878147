import FIconWrapper from '../../components/FIconWrapper'
import FInputField from '../../components/FInputField'
import FButton from '../../components/FButton'
import ESpinner from '../../components/ESpinner'
import sheetImage from '../../assets/images/sheets.png'

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdPlaylistAdd } from 'react-icons/md'
const OnBoard = () => {
  const [value, setValue] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()
  function extractSheetId(link) {
    const splitValue = link.split('/')
    let sheetId = ''
    for (let i = 0; i < splitValue.length; i++) {
      if (splitValue[i] === 'd') {
        sheetId = splitValue[i + 1]
      }
    }
    return sheetId
  }
  
  const recentSheets = JSON.parse(localStorage.getItem('recent_sheets')) || []
  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault()

    navigate(`/file/${extractSheetId(value)}`)
  }

  return (
    <div className={'grow flex items-center justify-center'}>
      <div className={'border bg-white rounded p-5 w-4/12 primary-shadow'}>
        <span className={'text-gray-500 font-medium text-sm   block my-5'}>
          *Enter google sheet id to create exceptions and requirements sheet
        </span>

        <FInputField
          className={'w-full'}
          placeholder={'document'}
          type={'text'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className={'mt-3'}>
          <FButton onClick={handleSubmit} className={'w-full'}>
            <FIconWrapper>
              <MdPlaylistAdd size={25} />
              Open Sheet
              <ESpinner isVisible={isLoading} />
            </FIconWrapper>
          </FButton>
        </div>
        {recentSheets.length ? <div className='mt-4'>
          <span >Rcently Opened</span>
          {recentSheets.map((sheet, index) => (
            <div key={index} className=' w-full my-2'>
              <Link to={`/file/${sheet.id}`} className='flex items-center border-b p-1 mr-2'>
                <img src={sheetImage} width={30} alt={'sheet'} />
                  {sheet.name}
                </Link>
              </div>
          ))}
          </div> : null}
      </div>

    </div>
  )
}
export default OnBoard
