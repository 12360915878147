import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { BiTable } from 'react-icons/bi'
import React, { useContext } from 'react'
import { axiosInstance } from '../../../api/requister'
import ESpinner from '../../../components/ESpinner'
import { useParams } from 'react-router'
import { MyContext } from '../../../context/WarningsContenxt'

const ENarsBtn = () => {
  const { refetchData } = useContext(MyContext)

  const [isLoading, setIsLoading] = React.useState(false)
  const { id } = useParams()

  const handleStartEsnars = () => {
    setIsLoading(true)
    axiosInstance.get(`util/esAndRsStatus?id=${id}`).then((result) => {
      const isEmpty = result.data.isEmpty
      if (isEmpty){
        axiosInstance
        .get(`util/createEsAndRs?id=${id}`)
        .then(() => {
          refetchData()

          window.open(`/detail/${id}`, '_blank')
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
      }else {
        setIsLoading(false)
        window.open(`/detail/${id}`, '_blank')
      }
    })
  }
  return (
    <>
      <FButton onClick={handleStartEsnars}>
        <FIconWrapper>
          <BiTable size={20} />
          Create E/Rs
          <ESpinner isVisible={isLoading} />
        </FIconWrapper>
      </FButton>
    </>
  )
}
export default ENarsBtn
