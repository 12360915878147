const FInputField = ({ inputRef, className, ...props }) => {
  return (
    <>
      <input
        ref={inputRef}
        className={`${className} border border-gray-300  p-1 rounded bg-gray-100`}
        {...props}
      />
    </>
  )
}
export default FInputField
