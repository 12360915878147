import { AiOutlineEdit } from 'react-icons/ai'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { formatAmount, generateKey } from '../../helpers/utils'
import { useEffect, useState, } from 'react'
import CreatableSelectInput from './TableComponents/CreatableSelectInput'
import SelectInput from './TableComponents/SelectInput'
import TextInput from './TableComponents/TextInput'
const calculateLengths = (input = {}) => {
    const output = []
    for (const key in input) {
      if (input[key]) {
        const values = input[key]
        let maxLength = 0
        let minLength = Number.MAX_SAFE_INTEGER
        for (const value of values) {
          const length = value.trim().length
          if (length > maxLength) {
            maxLength = length
          }
          if (length < minLength) {
            minLength = length
          }
        }
        output.push({
          name: key,
          maxLength: maxLength,
          minLength: minLength,
        })
      }
    }
    return output
  }
const DataTable = ({
  GPTmockData,
  handleUpdate,
  updateGPTmockData,
  docNames,
  toggleFileModal,
  activeRow
}) => {
    const [columns, setColumns] = useState([])
    const [data, setData] = useState({})
    const [readOnlyInputs, setReadOnlyInputs] = useState({})
    const mapData = () => {
        let allColumns = {}
        const allData = {}
        const readOnly = {}
        Object.entries(GPTmockData).forEach(([rowNumber, record]) => {
            let dataRow = {}
            record.values.forEach(value => {
                dataRow[value.columnName] = value.columnValue
                allColumns[value.columnName] = Array.isArray(allColumns[value.columnName]) ? [...allColumns[value.columnName], value.columnValue.toString()] : [value.columnValue.toString()]
                allColumns[value.columnName] = [...allColumns[value.columnName], value.columnName]
                if (value.readOnly) {
                  readOnly[rowNumber] = Array.isArray(readOnly[rowNumber]) ? [...readOnly[rowNumber], value.columnNumber] : [value.columnNumber]
                }
            })
            allData[rowNumber] = dataRow
            dataRow = {}
        })
        allColumns = calculateLengths(allColumns)
        for (const key in allColumns) {
            // allColumns[key].color = GPTmockData[key].color
            allColumns[key].columnNumber = Object.values(GPTmockData)[0].values.find(item => item.columnName === allColumns[key].name).columnNumber
            allColumns[key].width = allColumns[key].maxLength * 12
            allColumns[key].sticky = allColumns[key].columnNumber === 1 || allColumns[key].columnNumber === 2 || allColumns[key].columnNumber === 3
        }
        setReadOnlyInputs(readOnly)
        setColumns(allColumns)
        setData(allData)
    }
    useEffect(() => {
        if (GPTmockData) {
            mapData()
        }
    }, [GPTmockData])
    const getColumnNumber = name => columns.find(column => column.name === name).columnNumber
    const getColumnName = name => columns.find(column => column.name === name).name
    const getColumnWidth = name => columns.find(column => column.name === name).width
  return (
    <div
      className={'overflow-x-auto'}
      style={{
        maxHeight: 'calc(100vh - 370px)',
        overflowY: 'auto',
      }}>
      {GPTmockData && (
        <table
          className={'table-layout  overflow-x-auto'}
          style={{ tableLayout: 'fixed'}}
          >
          <thead className="">
            <tr
              className={'border-b bg-blue-600  text-white sticky top-0 z-20'}>
                {columns.map((column, index) => (
                    <th
                        className={'p-2'}
                        key={generateKey()}
                        style={
                        column.sticky
                            ? {
                                position: 'sticky',
                                left: (index % 3) * 45,
                                backgroundColor: '#1e54cc',
                            }
                            : {
                                position: 'unset',
                            }
                        }>
                        <span className={'whitespace-nowrap'}>
                        {column.name}
                        </span>
                    </th>
                    ))}
            </tr>
          </thead>
          <tbody className="">
            {Object.entries(data).map(([rowNumber, row]) => (
                <tr key={generateKey()} className={`border ${rowNumber == activeRow ? 'bg-gray-400' : 'even:bg-gray-200'} `}>
                    {Object.entries(row).map(([column, cell]) => (
                        <td 
                        style={
                            getColumnNumber(column) < 4
                            ? {
                                position: 'sticky',
                                left: (getColumnNumber(column) - 1) * 45,
                                boxShadow: '0 0 0 3px #eaeaea',
                                backgroundColor: '#eaeaea',
                                zIndex: 12
                            }
                            : {
                                position: 'unset',
                            }
                          }
                        className="border-b whitespace-nowrap text-sm border border-gray-300 py-0 px-3 " 
                        key={generateKey()}
                        >
                            <div className="flex gap-1 items-center  justify-between">
                                {getColumnNumber(column) === 3 ? (
                                    <CreatableSelectInput
                                    color={GPTmockData[rowNumber]?.color}
                                    textColor={GPTmockData[rowNumber]?.textColor}
                                    cell={cell}
                                    columnName={getColumnName(column)}
                                    columnNumber={getColumnNumber(column)}
                                    docNames={docNames}
                                    toggleFileModal={toggleFileModal}      
                                    rowNumber={rowNumber}
                                    handleUpdate={handleUpdate}                              
                                    updateGPTmockData={updateGPTmockData}
                                    />
                                ) : getColumnNumber(column) === 5 && row['Doc / Instrument Name'] === 'Tideland Search' ? (
                                    <SelectInput
                                        cell={cell}
                                        columnName={getColumnName(column)}
                                        columnNumber={getColumnNumber(column)}
                                        docNames={docNames}
                                        rowNumber={rowNumber}
                                        handleUpdate={handleUpdate}                              
                                        updateGPTmockData={updateGPTmockData}
                                    />
                                ) : <TextInput
                                        width={getColumnWidth(column)}
                                        readOnly={readOnlyInputs[rowNumber]?.includes(getColumnNumber(column))}
                                        cell={cell}
                                        columnName={getColumnName(column)}
                                        columnNumber={getColumnNumber(column)}
                                        docNames={docNames}
                                        rowNumber={rowNumber}
                                        handleUpdate={handleUpdate}                              
                                        updateGPTmockData={updateGPTmockData}
                                />}
                            </div>
                        </td>
                    ))}
                </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
export default DataTable
