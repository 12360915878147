import { Fragment } from "react"
import ESpinner from "../../../components/ESpinner"
import FModal from "../../../components/FModal"
import { EditText, EditTextarea } from "react-edit-text"
import FLabel from "../../../components/FLabel"
import FButton from "../../../components/FButton"
import FCollapse from "../../../components/FCollapse"
import { generateKey } from "../../../helpers/utils"

const RowModal = ({
    selectFileRecord,
    showFileModal,
    setShowFileModal,
    isRequesting,
    chainOfTitles,
    saveChainOfTitleCell,
    handelChainOfTitles,
    docCharacterstics,
    saveDocCharacterstics,
    childDocs,
    toggleFileModal,
    legalData,
    legalInfo,
    saveLegalData,
    handelLegalData,
    uploadLegalData,
    handelLegalInfo,
    filedMapData,
    saveFieldMapData,
    handelFiledMapData
}) => {
    return (
        <FModal
        title={selectFileRecord?.cell}
        isOpen={showFileModal}
        isAutoWidth={true}
        width={'w-12/12'}
        setIsOpen={setShowFileModal}>
        {isRequesting ? (
          <div className={'flex justify-center items-center w-full'}>
            <ESpinner isVisible={true} />
          </div>
        ) : (
          <div className={'p-1'}>
            <div className={'grid grid-cols-2 gap-4'}>
                <div className={''}>
                  {chainOfTitles && chainOfTitles.length ? (
                  <Fragment>
                      {chainOfTitles.map((item, index) => (
                          <div  key={generateKey()} className={'grid grid-cols-2 border bg-white p-50'}>
                              <span>{item.columnName}: </span>
                              <EditText
                                  placeholder="......"
                                  defaultValue={item.columnValue.toString()}
                                  //value={item.columnValue.toString()}
                                  name={item.columnName}
                                  onSave={({ name, value, previousValue }) => {
                                    // if (item.columnValue !== value) {
                                        saveChainOfTitleCell({
                                            rowNumber: item.rowNumber,
                                            columnNumber: item.columnNumber,
                                            columnValue: value,
                                        })
                                    // }
                                  }}
                                  onChange={(e) => {
                                      //handelChainOfTitles(item.columnNumber, e.target.value)
                                  }}
                              />
                          </div>
                      ))}
                      <hr style={{margin: "16px 0 "}}/>
                  </Fragment>
                  ) : null}
                </div>
              <div className={''}>
                {docCharacterstics && docCharacterstics.length ? (
                    <Fragment>
                        {docCharacterstics?.map((chareteristic) => (
                            <div className={'flex gap-1'} key={generateKey()}>
                                <div className={'grid grid-cols-2'}>
                                    <div className={'flex gap-2 items-center '}>
                                        <input
                                            id={chareteristic.name}
                                            type={'checkbox'}
                                            defaultChecked={chareteristic.status}
                                            onClick={(e) => {
                                                saveDocCharacterstics(chareteristic)
                                            }}
                                        />
                                        <FLabel htmlFor={chareteristic.name}>
                                            {chareteristic.name}
                                        </FLabel>
                                    </div>
                                </div>
                                <div className={'flex gap-1 items-center'}></div>
                            </div>
                        ))}
                        <hr className={''} style={{margin: "16px 0 "}}/>

                    </Fragment>
                ) : null}
                {childDocs && childDocs.length ? (
                    <Fragment>
                      <div className={'grid grid-cols-2   p-50'}>
                        <span>Document Name: </span>
                        <span>Page Number: </span>
                      </div>
                    {childDocs?.map((childDoc, index) => (
                      <div key={generateKey()} className={'grid grid-cols-2 border bg-white p-50'}>
                          <span>{childDoc.doc_name}</span>
                          <span>{childDoc.page_number}</span>
                      </div>
                    ))}
                        <hr className={''} style={{margin: "16px 0 "}}/>
                </Fragment>
                ) : null}
              </div>
            </div>
            {selectFileRecord && selectFileRecord?.cell ? (
              <Fragment>
                {selectFileRecord?.cell === "VESTING DEED" && (
                  <Fragment>
                    {legalData.pageNumber || legalData.propertyDimensions || legalInfo.legalData ? (
                      <Fragment>
                        <div>
                          <hr style={{margin: "16px 0 "}}/>
                          <h4>Legal</h4>
                            <div className={'grid grid-cols-2 border bg-white p-50'}>
                                <span>{legalData.pageNumber.label}</span>
                                <EditText
                                    showEditIcon={true}
                                    placeholder="......"
                                    defaultValue={legalData.pageNumber.value}
                                    name={legalData.pageNumber.value}
                                    onSave={({ name, value, previousValue }) => {
                                        // if (legalData.pageNumber.value.columnValue !== value) {
                                            saveLegalData({
                                                rowNumber: legalData.pageNumber.rowNumber,
                                                columnNumber: legalData.pageNumber.columnNumber,
                                                value: value
                                            })
                                        // }
                                    }}
                                    onChange={(e) => {
                                        handelLegalData('pageNumber', e.target.value)
                                    }}
                                />
                            </div>
                            <div className={'grid grid-cols-2 border bg-white p-50'}>
                                <div className={'grid grid-cols-1 '}>
                                    <span>{legalData.propertyDimensions.label}</span>
                                    <EditTextarea
                                        formatDisplayText={(val => val.substring(0, 900))}
                                        rows={7}
                                        defaultValue={legalData.propertyDimensions.value}
                                        name={legalData.propertyDimensions.value}
                                        onSave={({ name, value, previousValue }) => {
                                            // if (legalData.propertyDimensions.value !== value) {
                                                saveLegalData({
                                                    rowNumber: legalData.propertyDimensions.rowNumber,
                                                    columnNumber: legalData.propertyDimensions.columnNumber,
                                                    value: value
                                                })
                                            // }
                                        }}
                                        onChange={(e) => {
                                            handelLegalData('propertyDimensions', e.target.value)
                                        }}
                                    />
                                </div>
                                <div>
                                    <img src={legalData.propertyImage.value} alt={'Property Image'}/>
                                    <FLabel>Replace {legalData.propertyImage.label}</FLabel>
                                    <input
                                        type={'file'}
                                        onChange={e => uploadLegalData({
                                            rowNumber: legalData.propertyImage.rowNumber,
                                            columnNumber: legalData.propertyImage.columnNumber,
                                            value: e.target.files[0]
                                        })}
                                    />
                                </div>
                            </div>
                            <hr style={{margin: "16px 0 "}}/>
                            <div className={'grid grid-cols-1 bg-white border  p-50'}>
                                <span>{legalInfo.legalData?.label}</span>
                                <EditTextarea
                                    showEditIcon={false}
                                    formatDisplayText={(val => val.substring(0, 900))}
                                    rows={12}
                                    placeholder="......"
                                    defaultValue={legalInfo.legalData.value}
                                    name={legalInfo.legalData.value}
                                    onSave={({ name, value, previousValue }) => {
                                        // if (legalInfo.legalData.value !== value) {
                                            saveLegalData({
                                                rowNumber: legalInfo.legalData.rowNumber,
                                                columnNumber: legalInfo.legalData.columnNumber,
                                                value:  value
                                            })
                                        // }
                                    }}
                                    onChange={(e) => {
                                        handelLegalInfo('legalData', e.target.value)
                                    }}
                                />
                            </div>
                            <hr style={{margin: "16px 0 "}}/>
                            <div className={'grid grid-cols-1 border bg-white p-50'}>
                                <span>{'Mala props'}</span>
                                <div>
                                    {legalInfo.malaprops.length ? legalInfo.malaprops.map((i, index) => <span style={{margin: '8px'}} key={generateKey()}>{i}</span>) : '-----'}
                                </div>
                            </div>
                            <hr style={{margin: "16px 0 "}}/>
                            {legalData.extraLegalData.label && legalData.extraLegalData.value ? (
                                <Fragment>
                                <div className={'grid grid-cols-2 border bg-white p-50'}>
                                    <span>{legalData.extraLegalData.label}</span>
                                  <span>{legalData.extraLegalData.value}</span>
                                </div>
                                <hr style={{margin: "16px 0 "}}/>
                                </Fragment>
                            ) : null }
                            {legalData.propertyData.map((item, index) => (
                                <div key={generateKey()} className={'grid grid-cols-2 border bg-white p-50'}>
                                    <span>{item.label}</span>
                                    <EditText
                                        showEditIcon={false}
                                        placeholder="......"
                                        defaultValue={item.value.toString()}
                                        name={item.label}
                                        onSave={({ name, value, previousValue }) => {
                                            // if (item.value.toString() !== value) {
                                                saveLegalData({
                                                    rowNumber: item.rowNumber,
                                                    columnNumber: item.columnNumber,
                                                    value: value
                                                })
                                            // }
                                        }}
                                        onChange={(e) => {
                                            handelLegalData('propertyData', e.target.value, item.columnNumber, item.rowNumber)
                                        }}
                                    />
                                </div>
                            ))}
                            <div className={'grid grid-cols-2 border bg-white '}>
                                <FCollapse title={'Condo'}>
                                {legalData.condo.map((item, index) => (
                                    <div key={generateKey()} className={'grid grid-cols-2 border bg-white p-50'}>
                                        <span>{item.label}</span>
                                        <EditText
                                            showEditIcon={false}
                                            placeholder="......"
                                            defaultValue={item.value.toString()}
                                            name={item.label}
                                            onSave={({ name, value, previousValue }) => {
                                                // if (item.columnValue !== value) {
                                                    saveLegalData({
                                                        rowNumber: item.rowNumber,
                                                        columnNumber: item.columnNumber,
                                                        value: value
                                                    })
                                                // }
                                            }}
                                            onChange={(e) => {
                                                handelLegalData('condo', e.target.value, item.columnNumber, item.rowNumber)
                                            }}
                                        />
                                    </div>
                                ))}
                                </FCollapse>
                                <FCollapse title={'Filed Map'}>
                                {legalData.filedMap.map((item, index) => (
                                    <div key={generateKey()} className={'grid grid-cols-2 border bg-white p-50'}>
                                        <span>{item.label}</span>
                                        <EditText
                                            showEditIcon={false}
                                            placeholder="......"
                                            defaultValue={item.value.toString()}
                                            name={item.label}
                                            onSave={({ name, value, previousValue }) => {
                                                // if (item.columnValue !== value) {
                                                    saveLegalData({
                                                        rowNumber: item.rowNumber,
                                                        columnNumber: item.columnNumber,
                                                        value: value
                                                    })
                                                // }
                                            }}
                                            onChange={(e) => {
                                                handelLegalData('filedMap', e.target.value, item.columnNumber, item.rowNumber)
                                            }}
                                        />
                                    </div>
                                ))}
                                </FCollapse>
                            </div>
                        </div>
                      </Fragment>
                    ) : <p>Part of the data did not load</p>}
                  </Fragment>
                )}
                {selectFileRecord?.cell === "Filed Map" ? (
                  <Fragment>
                    {filedMapData.length ? (
                      <Fragment>
                        {filedMapData.map((item, index) => (
                            <div key={generateKey()} className={'grid grid-cols-2 border bg-white p-50'}>
                                <span>{item.label}</span>
                                <EditText
                                    showEditIcon={false}
                                    placeholder="......"
                                    defaultValue={item.value.toString()}
                                    name={item.label}
                                    onSave={({ name, value, previousValue }) => {
                                        // if (item.columnValue !== value) {
                                            saveFieldMapData({
                                                rowNumber: item.rowNumber,
                                                columnNumber: item.columnNumber,
                                                columnValue: value,
                                            })
                                        // }
                                  }}
                                  onChange={(e) => {
                                      handelFiledMapData(item.rowNumber, e.target.value)
                                  }}
                                />
                            </div>
                        ))}
                      </Fragment>
                    ) : null}
                  </Fragment>
                ) : null}
              </Fragment>
            ) : <p>No  selectFileRecord && selectFileRecord.cellData selected</p>}
            <div className={'flex gap-1 mt-2'}>
              <FButton btnType="secondary" onClick={() => toggleFileModal()}>
                Close
              </FButton>
            </div>
          </div>
        )}
      </FModal>
    )
}
export default RowModal
