const FLabel = ({ isRequired, htmlFor, className, children, ...props }) => {
  return (
    <div className={'flex gap-1'}>
      <label
        {...props}
        htmlFor={htmlFor}
        className={`font-normal
         text-xs text-gray-700 whitespace-nowrap ${className}`}>
        {children}
      </label>
      {isRequired === true && (
        <span className={'font-bold text-red-500'}>*</span>
      )}
    </div>
  )
}
export default FLabel
