import React, { useState } from 'react'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import axios from 'axios'
import draftToHtml from 'draftjs-to-html'
import ESpinner from '../../components/ESpinner'
import FIconWrapper from '../../components/FIconWrapper'
import FButton from '../../components/FButton'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {axiosInstance} from "../../api/requister"
function addPxToFontSizes(htmlText) {
  return htmlText.replace(/font-size:([^;]*);/g, 'font-size:$1px;')
}
const CustomWys = ({ instance, fileId, Range, callback }) => {
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = htmlToDraft(instance)
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )
    return EditorState.createWithContent(contentState)
  })

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const handleEdit = () => {
    setLoading(true)
    console.log(Range)
    // console.log(
    //   'wiii',
    //   draftToHtml(convertToRaw(editorState.getCurrentContent())),
    // )

    axiosInstance

      .post('util/esAndRs/', {
        id: fileId,
        range: Range,
        value: addPxToFontSizes(
          draftToHtml(convertToRaw(editorState.getCurrentContent())),
        ),
      })
      .then((res) => {
        console.log(res)
        callback()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={'p-3'}>
      <div className={'p-3 rounded border bg-gray-50'}>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <div className={'mt-3 flex gap-2'}>
        <FButton btnType={'primary'} onClick={handleEdit}>
          <FIconWrapper>
            <span className={'text-white'}>Save</span>
            <ESpinner isVisible={loading} />
          </FIconWrapper>
        </FButton>
        <FButton onClick={callback} btnType={'secondary'}>
          <span className={'text-gray-800'}>Cancel</span>
        </FButton>
      </div>
    </div>
  )
}
export default CustomWys
