import { useEffect } from "react"
import { AiOutlineEdit } from "react-icons/ai"
import CreatableSelect from 'react-select/creatable'

const CreatableSelectInput = ({color, textColor, columnNumber, columnName, cell, docNames, toggleFileModal, handleUpdate, updateGPTmockData, rowNumber}) => {
    const customStyles = {
        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999 
        }),
        control:  (provided, state) => ({
            ...provided,
            backgroundColor: color
        }),
        valueContainer:  (provided, state) => ({
            ...provided,
            backgroundColor: color,
            color: textColor
        }),
        singleValue:  (provided, state) => ({
            ...provided,
            backgroundColor: color,
            color: textColor
        })
    }
    const prevValue = cell
    return (
        <div className={'py-1 flex items-center gap-1 '}>
            <div style={{width: '300px'}} >
                <CreatableSelect
                
                styles={customStyles}
                value={{
                    value: cell,
                    label: cell,
                }}
                menuPortalTarget={document.body} 
                menuPosition={'fixed'}
                defaultValue={{
                    value: cell,
                    label: cell,
                }}
                onChange={(newValue) => {
                    if (newValue.label !== prevValue) {
                        handleUpdate(
                            parseInt(rowNumber),
                            columnNumber,
                            newValue.label,
                        )
                        updateGPTmockData(
                            parseInt(rowNumber),
                            columnNumber,
                            newValue.label,
                        )
                    }
                }}
                isClearable={false}
                options={docNames?.response?.map((item) => ({
                    value: item.names,
                    label: item.name,
                }))}
                />
            </div>

            <button
                className="flex gap-1 items-center border my-1 bg-gray-100 hover:bg-gray-200 border-gray-400 rounded p-1"
                onClick={() =>
                toggleFileModal({ cell, key: parseInt(rowNumber) })
                }
                >
                <AiOutlineEdit
                size={15}
                className="text-gray-800"
                />
            </button>
            </div>
    )
}
export default CreatableSelectInput