import { Editor } from 'react-draft-wysiwyg'
import FModal from "../../components/FModal"
import htmlToDraft from 'html-to-draftjs'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import { useState } from 'react'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import ESpinner from '../../components/ESpinner'
import { axiosInstance } from '../../api/requister'
import FInputField from '../../components/FInputField'
import FLabel from '../../components/FLabel'
import draftToHtml from 'draftjs-to-html'
import { toast } from 'react-toastify'

const AddNewEntryModal = ({isModalOpen, setIsModalOpen, fileId, section, Refetch}) => {
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState(() => {
        const blocksFromHTML = htmlToDraft('')
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )
        return EditorState.createWithContent(contentState)
    })
    const [data, setData] = useState({
        code: '',
        documentName: '',
        pageNumber: ''
    })
    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
    }
    const section_data = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const save = () => {
        if (section_data !== '<p></p>\n') {
        setLoading(true)
        axiosInstance
            .put('util/esAndRs/', {
            id: fileId,
            section: section,
            data: section_data,
            code: data['code'],
            documentName: data['documentName'],
            pageNumber: data['pageNumber']
        })
        .then((res) => {
            setLoading(false)
            Refetch()
            setIsModalOpen(!isModalOpen)
            toast.success("Saved!")
        })
        .catch((err) => {
            setLoading(false)
            console.log(err)
        })
        }

    
    }
    return (
    <FModal title={'Add New Entry'} isOpen={isModalOpen} setIsOpen={setIsModalOpen} >
        <div className={`p-3 rounded border bg-gray-50 ${section_data === '<p></p>\n' ? 'border-red-600' : ''}`}>
            <FLabel>Title Vested Section Data</FLabel>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
            />
        </div>
        <div className={'p-3 rounded border bg-gray-50'}>
            <FLabel>Code</FLabel>
            <FInputField
                className={'w-full'}
                onChange={(event) => setData({...data, code: event.target.value})}
                type='text'
            />
        </div>
        <div className={'p-3 rounded border bg-gray-50'}>
            <FLabel>Document Name</FLabel>
            <FInputField
                className={'w-full'}
                onChange={(event) => setData({...data, documentName: event.target.value})}
                type='text'
            />
        </div>
        <div className={'p-3 rounded border bg-gray-50'}>
            <FLabel>Page Number</FLabel>
            <FInputField
                className={'w-full'}
                onChange={(event) => setData({...data, pageNumber: event.target.value})}
                type='text'
            />
        </div>
      <div className={'mt-3 flex gap-2'}>
        <FButton btnType={'primary'} onClick={save} disabled={section_data === '<p></p>\n'}>
          <FIconWrapper>
            <span className={'text-white'}>Save</span>
            <ESpinner isVisible={loading} />
          </FIconWrapper>
        </FButton>
        <FButton onClick={() => setIsModalOpen(!isModalOpen)} btnType={'secondary'}>
          <span className={'text-gray-800'}>Cancel</span>
        </FButton>
      </div>
    </FModal>
    )
}
export default AddNewEntryModal