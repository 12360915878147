export const getAuthUserFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('user'))
}
export const getAuthUserNameFromLocalStorage = () => {
  return (
    getAuthUserFromLocalStorage().first_name +
    ' ' +
    getAuthUserFromLocalStorage().last_name
  )
}

//  const formatAmount = (amount) => {
//   if (amount === '') return `$${amount}`

//   if (/^\$?\d{1,3}(,\d{3})*(\.\d{2})?$/.test(amount)) {
//     return amount
//   }
//   const containsCommas = amount.includes(',')
//   // Split the price into dollars and cents
//   const dollars = amount.slice(0, -2)
//   const cents = amount.slice(-2)

//   // Format the dollars with commas
//   const formattedDollars = containsCommas
//       ? dollars
//       : dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//   let formattedPrice = `${formattedDollars}.${cents}`.replaceAll('$', '')

//   return `$${formattedPrice}`
// }
export const formatAmount = (amount) => {
  if (!amount) return ''

  // Remove all non-numeric characters except decimal point
  amount = amount.replace(/[^\d.]/g, '')

  // Limit to two decimal places
  amount = parseFloat(amount).toFixed(2)

  // Add commas for thousands separator
  amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Check if the last two digits are decimal values, add trailing zero if necessary
  const decimalIndex = amount.indexOf('.')
  if (decimalIndex >= 0 && decimalIndex < amount.length - 3) {
    amount = amount.padEnd(decimalIndex + 3, '0')
  }

  // Add dollar sign to the beginning
  amount = '$' + amount

  return amount
}
export const generateKey = (length = 16) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
}