import React, { createContext, useEffect, useState } from "react"
import { axiosInstance } from "../api/requister"
import { useParams } from "react-router"

const MyContext = createContext()
const WarningsContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const {id} = useParams()
    const fetchData = () => {
        setLoading(true)
        axiosInstance.get(`fileNotes/?id=${id}`)
        .then(res => {
            setLoading(false)
            setData(res.data)
        })
        .catch(err => {
            setLoading(false)
            console.log(err)
        })
    }
    useEffect(() => {
        if (id) fetchData()
    }, [id])
    const refetchData = () => {
        fetchData()
    }
    return (
        <MyContext.Provider value={{ data, refetchData, loading }}>
          {children}
        </MyContext.Provider>
      )
    
}
export { MyContext, WarningsContextProvider }
