import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import React, { useContext } from 'react'
import { axiosInstance } from '../../../api/requister'
import { MdUpdate } from 'react-icons/md'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { BiBookmark } from 'react-icons/bi'

const BookmarkBtn = () => {

    const fileId = useParams().id
    const handlebookMark = () => {
        axiosInstance
            .get(`/util/bookmark-working-abstract/?id=${fileId}`)
            .then((res) => {
            toast('Bookmark Added Successfully', {
                position: 'top-right',
                type: 'success',
            })
            console.log(res)
            })
            .catch((err) => {
            console.log(err)
            })
        }  
  return (
        <FButton onClick={handlebookMark}>
            <FIconWrapper>
            <BiBookmark size={20} />
            Bookmark
            </FIconWrapper>
        </FButton>
  )
}
export default BookmarkBtn
