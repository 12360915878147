import React, { Fragment, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { axiosInstance } from "../../api/requister"
import { useParams } from "react-router"
import FButton from "../../components/FButton"
import FIconWrapper from "../../components/FIconWrapper"
import { BiPlus, BiTrash } from "react-icons/bi"
import ESpinner from "../../components/ESpinner"
import { toast } from "react-toastify"

const ERTable = ({Refetch, data, narName, setInstance, setIsModalOpen, setRange, setIsAddNewEntryModalOpen, isAddNewEntryModalOpen}) => {
  const { id } = useParams()
  const [tableData, setTableData] = useState(Object.entries(data).map(([k, i]) => ({...i, key: k})))
  const [selectedKeys, setSelectedKeys] = useState([])
  const [deleting, setDeleting] = useState(false)
  const deleteEntries = () => {
    setDeleting(true)
    axiosInstance
    .patch(`util/esAndRs/`,  {
      id:id,
      ranges: selectedKeys
  })
    .then((res) => {
      toast(`${selectedKeys.length} ${selectedKeys.length === 1 ? 'Entry' : 'Entries'} Deleted`, {
        position: 'top-right',
        type: 'success',
      })
      Refetch()
      setDeleting(false)
    })
    .catch((err) => {
      setDeleting(false)
    })
  }
  const toggleKeys = (key) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(selectedKeys.filter(i => i !== key))
    } else {
      setSelectedKeys([...selectedKeys, key])
    }
  }
  const swap = (source, destination) => {
    axiosInstance
    .post(`util/esAndRs/swap/`,  {
      id:id,
      source:source,
      destination:destination
  })
    .then((res) => {
      console.log(res)
    })
    .catch((err) => {
      console.log(err)
    })
  }
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const parts = tableData[0].key.split(":")
    let startNumber = parseInt(parts[0].substring(1))
    const items = Array.from(tableData)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setTableData(items.map((entry, index) => {
      entry.key = `A${startNumber}:A${startNumber}` 
      startNumber = startNumber + 1
      return entry
    }))
    // console.log(items[result.source.index].key, items[result.destination.index].key)
    swap(items[result.source.index].key, items[result.destination.index].key)
  }
  return (
    <Fragment>
        <div className='w-full border-t flex align-items-center gap-2'>
            <FButton className={'my-2 '} onClick={() => setIsAddNewEntryModalOpen(!isAddNewEntryModalOpen)}>
              <FIconWrapper>
              <BiPlus size={20} />
                Add New Entry
              </FIconWrapper>
          </FButton>
          <FButton className={'my-2 '} onClick={() => deleteEntries()} disabled={deleting || selectedKeys.length === 0}>
              <FIconWrapper>
                {deleting ? (
                  <ESpinner isVisible={deleting} />
                ) : (
                  <Fragment>
                  <BiTrash size={20} />
                    Delete {selectedKeys.length ? selectedKeys.length : ''} {selectedKeys.length === 1 ? 'Entry' : 'Entries'}
                </Fragment>
                )}
              </FIconWrapper>
          </FButton>
          </div>
    <DragDropContext onDragEnd={handleDragEnd}>
      <table style={{
          backgroundColor: '#efefef',
        }}>
        <thead>
          <tr className={'sticky top-0 z-10'}>
          <th
            className={
              'whitespace-nowrap bg-blue-500 text-white p-2 border-x'
            }>
              {'Actions'}
          </th>
          <th
            className={
              'whitespace-nowrap bg-blue-500 text-white p-2 border-x'
            }>
              {narName}
          </th>
          <th
            className={
              'whitespace-nowrap bg-blue-500 text-white p-2 border-x'
              }>
              Code
          </th>
          <th
            className={
              'whitespace-nowrap bg-blue-500 text-white p-2 border-x'
            }>
            Document Name
          </th>
          <th
            className={
              'whitespace-nowrap bg-blue-500 text-white p-2 border-x'
            }>
            Page Number
          </th>
          </tr>
        </thead>
          <Droppable droppableId="table">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {tableData.map((item, index) => (
                <Draggable key={item.key} draggableId={item.key} index={index}>
                  {(provided) => (
                    <tr
                    className="bg-white  border-y text-sm "
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    >
                      <td className="flex justify-center items-center p-2">
                        {/* {item.key} */}
                      
                      <input
                          id={item.key}
                          type={'checkbox'}
                          className="p-2"
                          onClick={(e) => {
                              toggleKeys(item.key)
                          }}
                      />
                      </td>
                      <td
                      className={'border-x px-2  border-gray-300 cursor-pointer hover:bg-gray-200'}
                      dangerouslySetInnerHTML={{ __html: item.data }}
                      onClick={() => {
                        setInstance(item.data)
                        setIsModalOpen(true)
                        setRange(item.key)
                      }}

                      ></td>
                      <td className={'border-x px-2 border-gray-300'}>{item.code}</td>
                      <td className={'border-x px-2 border-gray-300'}>{item.documentName}</td>
                      <td className={'border-x px-2 border-gray-300'}>{item.pageNumber}</td>
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              </tbody>
            )}
          </Droppable>
      </table>
    </DragDropContext>
    </Fragment>

  )

}
export default ERTable
