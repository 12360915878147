import PropTypes from 'prop-types'

const FButton = ({ children, className, onClick, type, btnType, ...props }) => {
  const btnStyles = {
    danger: 'bg-red-500 text-white rounded  disabled:bg-red-300',
    secondary:
      'border rounded border-gray-300 text-gray-800 bg-gray-200 hover:bg-gray-300  disabled:bg-gray-300',
    primary:
      'border rounded  border-blue-400 bg-blue-600 text-white ring-blue-500 hover:bg-blue-700 focus:ring-2 focus:ring-offset-1 disabled:border-blue-300 disabled:bg-blue-300 disabled:cursor-not-allowed',
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={` ${className} ${btnStyles[btnType] || btnStyles['primary']}
      flex justify-center items-center inset-2 shadow-sm h-8 font-medium px-2 leading-3 text-xs primary-shadow`}
      {...props}>
      {children}
    </button>
  )
}
export default FButton

FButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  btnType: PropTypes.string,
  type: PropTypes.string,
}

FButton.defaultProps = {
  type: 'button',
}
