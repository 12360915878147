import React from 'react'
import FButton from "./FButton"
import FIconWrapper from "./FIconWrapper"
import {BiBookmark} from "react-icons/bi"
import {IoReload} from "react-icons/io5"
import {axiosInstance} from "../api/requister"
import ESpinner from "./ESpinner";

const SectionTitle = ({ title, esAndRs , fileID}) => {
    const [loading, setLoading] = React.useState(false)
    const reCreateESAndRS = () => {
        setLoading(true)
        axiosInstance
        .get(`util/createEsAndRs?id=${fileID}`)
        .then(() => {
            esAndRs?.Refetch()
            setLoading(false)
        })
        .catch((err) => {
            setLoading(false)
        })
    }
  return (
    <div className={'col-span-4  py-2 my-2'}>
      <div className={'flex items-center w-full gap-3'}>
        <span className={'text-2xl'}>{title}</span>
        <hr className={'border-t flex-grow border-gray-300'} />
          <FButton onClick={reCreateESAndRS}>
              <FIconWrapper>
                  {loading ? <ESpinner isVisible={loading} /> : <IoReload size={20} />}
                  Re Create Es and Rs
              </FIconWrapper>
          </FButton>
      </div>
    </div>
  )
}
export default SectionTitle
