import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { BsTrash } from 'react-icons/bs'
import { useCustomAxios } from '../../Hooks/useAxios'
import FModal from '../../components/FModal'
import React, { useState } from 'react'
import { axiosInstance } from '../../api/requister'
import FFormWrapper from '../../components/FFormWrapper'
import FLabel from '../../components/FLabel'
import FInputField from '../../components/FInputField'
const Settings = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [value, setValue] = useState('')

  const [selectedDomain, setSelectedDomain] = useState(null)

  const domains = useCustomAxios({
    url: '/domain/',
    method: 'GET',
  })

  const handleDeleteDomain = (id) => {
    axiosInstance
      .delete(`/domain/${id}`)
      .then((res) => {
        console.log(res)
        setIsDeleteModalOpen(false)
        domains.Refetch()
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleCreateDomain = () => {
    axiosInstance
      .post(`/domain/`, { domain: value })
      .then((res) => {
        console.log(res)
        setIsCreateModalOpen(false)
        setValue('')
        domains.Refetch()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (domains.loading) {
    return <div>Loading</div>
  } else {
    return (
      <>
        <FModal
          isAutoWidth
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          title="Delete Domain">
          <div className="flex flex-col p-1">
            <span className={'text-left text-sm'}>
              Are you sure you want to delete this domain?
            </span>
            <div className={'w-fit flex gap-1 mt-2'}>
              <FButton onClick={() => handleDeleteDomain(selectedDomain.id)}>
                <span className={'text-sm'}>Delete</span>
              </FButton>
              <FButton
                btnType={'secondary'}
                onClick={() => {
                  setIsDeleteModalOpen(false)
                }}>
                <span className={'text-sm'}>Cancel</span>
              </FButton>
            </div>
          </div>
        </FModal>

        <FModal
          isOpen={isCreateModalOpen}
          setIsOpen={setIsCreateModalOpen}
          title="Create Domain">
          <div className="flex flex-col p-1">
            <FFormWrapper>
              <FLabel htmlFor={'domain'}>Domain Name</FLabel>
              <FInputField
                value={value}
                onChange={(e) => setValue(e.target.value)}
                id="domain"
                type="text"
                placeholder="Enter domain"
                name="domain"
              />
            </FFormWrapper>

            <div className={'w-fit flex gap-1 mt-2'}>
              <FButton onClick={() => handleCreateDomain()}>
                <span className={'text-sm'}>Create</span>
              </FButton>
              <FButton
                btnType={'secondary'}
                onClick={() => {
                  setIsCreateModalOpen(false)
                }}>
                <span className={'text-sm'}>Cancel</span>
              </FButton>
            </div>
          </div>
        </FModal>

        <div className="flex flex-col p-5">
          <div className={'flex gap-20 items-center  '}>
            <span className={'text-left text-2xl w-full'}>Allowed domains</span>
          </div>
          <span className={'text-left text-sm'}>
            You can allow only specific domains to be used in the email
            addresses of logging
          </span>

          <div className={'my-5 '}>
            {domains.response.map((domain) => {
              return (
                <div
                  key={domain.id}
                  className={
                    'justify-between  bg-white border-b flex gap-5 p-2 w-4/12'
                  }>
                  <span className={'text-sm'}>{domain.domain}</span>
                  <div
                    onClick={() => {
                      setSelectedDomain(domain)
                      setIsDeleteModalOpen(true)
                    }}
                    className={
                      'flex flex-col text-gray-500 items-center justify-center p-1 rounded hover:bg-gray-100 cursor-pointer'
                    }>
                    <FIconWrapper>
                      <BsTrash />
                    </FIconWrapper>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={'w-fit'}>
            <FButton onClick={() => setIsCreateModalOpen(true)}>
              <span className={'text-sm'}>Add domain</span>
            </FButton>
          </div>
        </div>
      </>
    )
  }
}
export default Settings
