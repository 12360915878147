import { useGoogleLogin } from '@react-oauth/google'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_URL } from '../api/utils'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import FIconWrapper from '../components/FIconWrapper'
import { FcGoogle } from 'react-icons/fc'
import queryString from 'query-string'
const Login = () => {
  const navigate = useNavigate()
  const [isRedirect, setIsRedirect] = useState(null)
  useEffect(() => {
    console.log(`something here`)
    const queryParams = queryString.parse(location.search)
    if (queryParams.redirect) {
      setIsRedirect(queryParams.redirect)
    }
    console.log(`just set the redirect ${queryParams.redirect}`)
  }, [location.search])
  const handleLogin = (access_token) => {
    axios
      .get(`${BASE_URL}/login/google?access_token=${access_token}`)
      .then((res) => {
        console.log(res)
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        localStorage.setItem(
          'user',
          JSON.stringify({
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            username: res.data.username,
            email: res.data.email,
          }),
        )
        if(isRedirect){
          navigate(isRedirect, { replace: true })
        }else{
          navigate('/onBoard', { replace: true })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onSuccess = (response) => {
    handleLogin(response.access_token)
  }
  const onFailure = (response) => {
    console.log(response)
  }
  const loginGoogle = useGoogleLogin({
    onSuccess,
    onError: onFailure,
  })
  const [isPageLoaded, setIsPageLoaded] = React.useState(false)

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    setIsPageLoaded(true)
    if (localStorage.getItem('access_token')) {
      if(queryParams.redirect){
        return navigate(queryParams.redirect, { replace: true })
      }else{
        return navigate('/onBoard', { replace: true })
      }
    }
  }, [])
  if (isPageLoaded) {
    return (
      <div className={'min-h-screen flex flex-col justify-center items-center'}>
        <div className={'flex flex-col gap-2 mt-auto items-center '}>
          <img src={logo} className={'w-fit'} />
          <h1 className={'text-2xl font-bold text-gray-800'}>
            Welcome to Hugh joseph
          </h1>
          <button
            className={
              'bg-white hover:bg-gray-100  text-sm shadow-sm border font-medium  border-gray-300 text-gray-600 p-2 rounded-md'
            }
            onClick={loginGoogle}>
            <FIconWrapper>
              <FcGoogle size={20} />
              Sign in with google
            </FIconWrapper>
          </button>
        </div>
        <span
          className={
            'text-sm  mt-auto  border-t border-gray-300 p-3 w-full text-gray-600 '
          }>
          copy right &copy; {new Date().getFullYear()} hug joseph
        </span>
      </div>
    )
  }
}
export default Login
