import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import React from 'react'
import { axiosInstance } from '../../../api/requister'
import { MdUpdate } from 'react-icons/md'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { BiCodeAlt } from 'react-icons/bi'

const CreateXMLBtn = () => {
    const fileId = useParams().id
    const handleCreateXML = () => {
        axiosInstance
            .get(`/util/xml/?id=${fileId}`)
            .then((res) => {
                toast('XML Created Successfully', {
                position: 'top-right',
                type: 'success',
                })
            })
            .catch((err) => {
                console.log(err)
            })
        }
  return (
    <FButton onClick={handleCreateXML}>
        <FIconWrapper>
        <BiCodeAlt size={20} />
        Create XML
        </FIconWrapper>
    </FButton>
  )
}
export default CreateXMLBtn
