import { useLocalStorage } from '../Hooks/useLocalStorage'
import { HiChevronDown } from 'react-icons/hi'
import React, { useEffect } from 'react'
const FCollapse = ({ title, callback, children }) => {
  const [isCollapse, setIsCollapse] = useLocalStorage('FCollapse', false)
  useEffect(() => {
    setIsCollapse(false)
  }, [])
  return (
    <>
      <div>
        <div
          onClick={() => {
            setIsCollapse(!isCollapse)
            callback && callback()
          }}
          style={{
            borderRadius: 'inherit',
          }}
          className={
            'collapse-header f-row select-none ' +
            ` justify-between hover:cursor-pointer hover:bg-gray-900 bg-gray-${
              isCollapse ? '900' : '800'
            } p-1 text-gray-200`
          }>
            {title}

            <HiChevronDown
            style={{
              minWidth: '20px',
              minHeight: '20px',
            }}
            className={`mx-2 text-gray-300 border rounded-full border-gray-600 transform ${
              isCollapse ? 'rotate-180' : ''
            }`}
            size={25}
          />

        </div>
        <div
          className={`collapse-body border-t border-dashed border-gray-700 ${
            isCollapse ? 'block bg-gray-900' : 'hidden'
          }`}>
          {children}
        </div>
      </div>
    </>
  )
}
export default FCollapse
