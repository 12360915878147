import { useParams } from "react-router"
import { useCustomAxios } from "../../Hooks/useAxios"
import sheet from '../../assets/images/sheets.png'
import FIconWrapper from "../../components/FIconWrapper"
import { BiBookmark, BiCodeAlt, BiLinkExternal } from "react-icons/bi"
import Header from "./Header"
import { Fragment, useEffect } from "react"
import Footer from "./Footer"
import FButton from "../../components/FButton"
import { axiosInstance } from "../../api/requister"
import ENarsBtn from "../Dashbaord/Buttons/ENarsBtn"
import UpdateTitleBtn from "../Dashbaord/Buttons/UpdateTitleBtn"
import BookmarkBtn from "../Dashbaord/Buttons/BookmarkBtn"
import CreateXMLBtn from "../Dashbaord/Buttons/CreateXMLBtn"
import FileDetails from "./FileDetails"
import { WarningsContextProvider } from "../../context/WarningsContenxt"

const DefaultLayout = ({children, withButtons, withFileDetails, withCharacteristic, stickyFileDetails}) => {
    const accessToken = localStorage.getItem('access_token')
    if (!accessToken) {
        window.location.href = '/'
    } else {
        const fileId = useParams().id
        const SheetMetaData = useCustomAxios({
            url: `sheetName/?id=${fileId}`,
            method: 'GET',
          })
          useEffect(() => {
            const recentSheets = JSON.parse(localStorage.getItem('recent_sheets')) || []
            if (SheetMetaData?.response?.sheetName && recentSheets.some(sh => sh.id === fileId) === false) {
                localStorage.setItem('recent_sheets', JSON.stringify([...recentSheets, {
                    id: fileId,
                    name: SheetMetaData?.response?.sheetName
                }]))
            }
          }, [SheetMetaData.response])
    return !SheetMetaData.loading && (
    <Fragment>
            <main className={'main min-h-screen flex flex-col bg-gray-100'}>
                <Header>
                    <div className={'flex gap-1 items-center bg-white w-full '}>
                        <img src={sheet} width={45} alt={'sheet'} />
                        <div className={'flex flex-col items-start w-full'}>
                            <div className={'flex gap-1 justify-between  w-full flex-grow'}>
                                <span className={'text-l text-gray-900 font-medium'}>
                                    {SheetMetaData?.response?.sheetName}
                                </span>
                                {withButtons && (
                                    <div className={'flex gap-1'}>

                                        <BookmarkBtn />

                                        <UpdateTitleBtn />

                                        <ENarsBtn />
                                    
                                        <CreateXMLBtn />

                                    </div>
                                )}
                        </div>

                        <a href={`https://docs.google.com/spreadsheets/d/${fileId}`} target="_blank" rel="noopener noreferrer" className={'text-sm font-medium text-blue-600'}>
                        <FIconWrapper>
                            Visit actual sheet
                            <BiLinkExternal />
                        </FIconWrapper>
                        </a>
                    </div>
                    </div>
                </Header>
                {withFileDetails && (
                    <FileDetails withCharacteristic={withCharacteristic} stickyFileDetails={stickyFileDetails}/>
                )}
                {children}
            <Footer />
            </main>
    </Fragment>

    )
    }
}
export default DefaultLayout