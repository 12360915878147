import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import React, { useContext } from 'react'
import { axiosInstance } from '../../../api/requister'
import { MdUpdate } from 'react-icons/md'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { MyContext } from '../../../context/WarningsContenxt'

const UpdateTitleBtn = () => {
    const { refetchData } = useContext(MyContext)

    const fileId = useParams().id
    const handleUpdateTitle = () => {
        axiosInstance
          .get(`/util/updateTitle/?id=${fileId}`)
          .then((res) => {
            toast('Title Updated Successfully', {
              position: 'top-right',
              type: 'success',
            })
            refetchData()

          })
          .catch((err) => {
            console.log(err)
          })
      }      
  return (
    <FButton onClick={handleUpdateTitle}>
        <FIconWrapper>
        <MdUpdate size={20} />
        Update Title
        </FIconWrapper>
    </FButton>
  )
}
export default UpdateTitleBtn
