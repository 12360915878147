import React, { Fragment } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import ESpinner from '../../components/ESpinner'
import CustomWys from './CustomWys'
import FModal from '../../components/FModal'
import { useCustomAxios } from '../../Hooks/useAxios'
import sheet from "../../assets/images/sheets.png";
import FButton from "../../components/FButton";
import FIconWrapper from "../../components/FIconWrapper";
import {BiBookmark, BiCodeAlt, BiLinkExternal, BiPlus} from "react-icons/bi";
import {MdOutlineCancel, MdUpdate} from "react-icons/md";
import ENarsBtn from "../Dashbaord/Buttons/ENarsBtn";
import DefaultLayout from '../Layout/DefaultLayout'
import AddNewEntryModal from './AddEntryModal'
import ERTable from './Dragablle'

const DetailEsnars = () => {
  
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isAddNewEntryModalOpen, setIsAddNewEntryModalOpen] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [instance, setInstance] = React.useState('')
  const { id, narName } = useParams()
  // eslint-disable-next-line no-unused-vars
  const [range, setRange] = React.useState('')
  // eslint-disable-next-line no-unused-vars

    const SheetMetaData = useCustomAxios({
        url: `sheetName/?id=${id}`,
        method: 'GET',
    })
    const fileDetailsUpper = useCustomAxios({
        url: `fileDetails/?id=${id}`,
        method: 'GET',
    })

  const { response, loading, Refetch } = useCustomAxios({
    method: 'GET',
    url: `util/esAndRs?id=${id}`,
  })
  function ObjectTable({ object, setInstance, setIsModalOpen, setRange }) {
    return (
        <ERTable
        setIsAddNewEntryModalOpen={setIsAddNewEntryModalOpen}
        isAddNewEntryModalOpen={isAddNewEntryModalOpen}
        data={object}
        narName={narName}
        setInstance={setInstance}
        setIsModalOpen={setIsModalOpen} 
        Refetch={Refetch}
        setRange={setRange} />
    )
  }

    return (
      <DefaultLayout withFileDetails >
        <FModal title={narName} isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <CustomWys
            Range={range}
            fileId={id}
            callback={() => {
              setIsModalOpen(false)
              Refetch()
            }}
            instance={instance}
          />
        </FModal>

        {loading ? (
          <div className={'f-col-center-center mt-10'}>
          <ESpinner isVisible={loading} />
          <span>loading...</span>
        </div>
        ) : (
        <Fragment>

              <div className={'overflow-x-auto'}>
                <ObjectTable 
                object={response[narName]} 
                setInstance={setInstance}
                setIsModalOpen={setIsModalOpen} 
                setRange={setRange} />
              </div>
              <AddNewEntryModal
              isModalOpen={isAddNewEntryModalOpen}
              setIsModalOpen={setIsAddNewEntryModalOpen}
              fileId={id}
              Refetch={Refetch}
              section={narName}
              />
        </Fragment>)
        }
      </DefaultLayout>
    )
}

export default DetailEsnars
