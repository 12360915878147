import { Outlet } from 'react-router'
import React from 'react'
const ProtectedRoutes = () => {
  const accessToken = localStorage.getItem('access_token')
  // console.log(!accessToken)
  if (!accessToken) {
    window.location.href = '/'
  } else {
    return (
      <main className={'main min-h-screen  flex flex-col bg-gray-100'}>
        <Outlet />
      </main>
    )
  }
}
export default ProtectedRoutes
