import { useEffect, useState } from "react"
import Select from "react-select"

const SelectInput = ({columnNumber, cell,  handleUpdate, updateGPTmockData, rowNumber, columnName}) => {
    const [prevValue, setPrevValue] = useState(cell)
    return (
        <div style={{width: '200px'}}>
        <Select
            // menuPortalTarget={document.body}
            // value={{
            //     value: prevValue,
            //     label: prevValue,
            // }}
            defaultValue={{
                value: cell,
                label: cell,
            }}
            onChange={(newValue) => {
                if (newValue.label !== prevValue) {
                    handleUpdate(
                        parseInt(rowNumber),
                        columnNumber,
                        newValue.label,
                    )
                    updateGPTmockData(
                        parseInt(rowNumber),
                        columnName,
                        newValue.label,
                    )
                }
            }}
            // menuPosition={'fixed'}
            isClearable={false}
            options={[
                { label: 'UNCLAIMED', value: 'UNCLAIMED' },
                { label: 'CLAIMED', value: 'CLAIMED' },
            ]}
            className="w-full"
            />
            </div>
    )
}
export default SelectInput