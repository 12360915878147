import React from 'react'
import logo from '../../assets/images/logo.png'
import FAvatar from '../../components/FAvatar'
import DropDown from '../Dashbaord/Menua'
import { Link } from 'react-router-dom'
import {
  getAuthUserFromLocalStorage,
  getAuthUserNameFromLocalStorage,
} from '../../helpers/utils'

const Header = ({children}) => {
  return (
    <header className="header sticky top-0 z-50">
      <div
        className={
          'bg-white border-b flex items-center py-2 px-3 justify-between '
        }>
        <div className='flex items-center w-full mr-1'>
          <Link to="/" className='border-r px-1 mr-2'>
            <img src={logo} alt="logo" />
          </Link>
          {children}  
        </div>
        <div className={'flex items-center gap-1 w-25 justify-center border-l'}>
          <FAvatar
            name={getAuthUserNameFromLocalStorage()}
            className={'rounded-full'}
          />
          <DropDown title={getAuthUserNameFromLocalStorage()} />
        </div>
      </div>
    </header>
  )
}
export default Header
