import { useEffect } from "react"
import FButton from "../../../components/FButton"
import FLabel from "../../../components/FLabel"
import FModal from "../../../components/FModal"

const CharacteristicsModal = ({
    isFileCharModalOpen,
    setIsFileCharModalOpen,
    fileCharectersitics,
    setFileCharectersitics,
    handleAddFileCharacteristics,
    fileCharacteristics
    }) => {
    return (
        <FModal
        isAutoWidth={true}
        width={'w-8/12'}
        title={'Manage File Characteristics'}
        isOpen={isFileCharModalOpen}
        setIsOpen={setIsFileCharModalOpen}>
        <div className={'p-1'}>
          <div className={'grid grid-cols-3'}>
            {fileCharectersitics?.map((chareteristic) => (
              <div className={'flex gap-1'} key={chareteristic.id}>
                <div className={'grid grid-cols-2'}>
                  <div className={'flex gap-2 items-center '}>
                    <input
                      id={chareteristic.name}
                      type={'checkbox'}
                      defaultChecked={chareteristic.status}
                      onClick={(e) => {
                        setFileCharectersitics((prevFileCharectersitics) => {
                          return prevFileCharectersitics.map(item => {
                            if (item.id === chareteristic.id) {
                              item.status = !item.status
                            }
                            return item
                          })
                        })
                      }}
                    />
                    <FLabel htmlFor={chareteristic.name}>
                      {chareteristic.name}
                    </FLabel>
                  </div>
                </div>
                <div className={'flex gap-1 items-center'}></div>
              </div>
            ))}
          </div>
          <div className={'flex gap-1 mt-2'}>
            <FButton
              btnType="primary"
              onClick={() => {
                handleAddFileCharacteristics(
                  fileCharacteristics?.response?.columnNumber,
                )
                setIsFileCharModalOpen(false)
              }}>
              save
            </FButton>
            <FButton
              btnType="secondary"
              onClick={() => {
                setIsFileCharModalOpen(false)
              }}>
              cancel
            </FButton>
          </div>
        </div>
      </FModal>
    )
}
export default CharacteristicsModal