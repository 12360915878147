// create an axios instance
import axios from 'axios'
import { BASE_URL } from './utils'

// eslint-disable-next-line camelcase
// import jwt_decode from 'jwt-decode'
// import daysjs from 'dayjs'
export const axiosFileInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

axiosFileInstance.interceptors.request.use(
    (config) => {
        // eslint-disable-next-line camelcase
        const access_token = localStorage.getItem('access_token')
        // eslint-disable-next-line camelcase
        if (access_token) {
            // eslint-disable-next-line camelcase
            config.headers['Authorization'] = `Bearer ${access_token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

axiosFileInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            // Refresh the access token
            const refreshToken = localStorage.getItem('refresh_token')
            if (refreshToken) {
                try {
                    const refreshResponse = await axios.post(
                        `${BASE_URL}/token/refresh/`,
                        {
                            refresh: refreshToken,
                        },
                    )

                    // Update the access token in the headers
                    axiosFileInstance.defaults.headers.common.Authorization = `Bearer ${refreshResponse.data.access}`

                    // Updating the access token in local storage
                    localStorage.setItem('access_token', refreshResponse.data.access)

                    // Re-send the original request
                    return axiosFileInstance(originalRequest)
                } catch (error) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    // window.location.replace('/')
                }
            }
        }
        return Promise.reject(error)
    },
)
