import './App.css'
import React, { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Login from './views/Login'
import ProtectedRoutes from './views/ProtectedRoutes'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Dashboard from './views/Dashbaord/Dashboard'
import Settings from './views/Settings/Settings'
import OnBoard from './views/onBoard/onBoard'
import { ToastContainer } from 'react-toastify'
import Esnars from './views/ENaRs/Esnars'
import DetailEsnars from './views/ENaRs/DetailEsnars'
import { WarningsContextProvider } from './context/WarningsContenxt'
function App() {

  return (
    <div className="App bg-white h-screen">
      <GoogleOAuthProvider clientId="338025050120-m0lsrvg957lvoal0tp5p3q3gfmedql4c.apps.googleusercontent.com">
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <BrowserRouter>

          <Routes>
            
            <Route path="/" element={<Login />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="file/:id" element={<WarningsContextProvider><Dashboard /></WarningsContextProvider>} />
              <Route path="onBoard" element={<OnBoard />} />
              <Route path="settings" element={<Settings />} />
              <Route path="/detail/:id" element={<Esnars />} />
              <Route
                path="/detail/:id/:narName"
                element={<WarningsContextProvider><DetailEsnars /></WarningsContextProvider>}
              />
            </Route>
            <Route path="*" element={<h1>404</h1>} />
          
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>

    </div>
  )
}

export default App
