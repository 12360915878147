import { useState } from "react"
import { formatAmount } from "../../../helpers/utils"
const customWidth = ['Book', 'Page', 'Amount', 'Notes']
const formatDate = (date) => {
    date = new Date(date)
    if (!isNaN(date)) {
        return date.toISOString().split('T')[0]
    } 
    return date
}
const TextInput = ({cell, columnName, columnNumber, handleUpdate, updateGPTmockData, rowNumber, width, readOnly}) => {
    const [prevValue, setPrevValue] = useState(cell)
    return (
        //     columnNumber === 9 || columnNumber === 10 ? (
        //         <div 
        //         className="w-full "
        //         style={customWidth.includes(columnName) ? {width: width} : {}}
        //         >
        //                     <textarea
        //                     className="border w-full h-full"
        //                     placeholder={`${
        //                         columnName === 'Amount' ? '$0.00' : '...'
        //                     }`}
        //                     defaultValue={
        //                         columnName === 'Amount'
        //                         ? formatAmount(cell.toString())
        //                         : cell.toString()
        //                     }
        //                     rows={3}
        //                     name={columnName}
        //                     onBlur={(e) => {
        //                         if (e.target.value.toString() !== prevValue.toString()) {
        //                             handleUpdate(
        //                                 parseInt(rowNumber),
        //                                 columnNumber,
        //                                 e.target.value,
        //                             )
        //                         }
        //                     }}
        //                     >
        //                     </textarea>
        // </div>

        //     ) : (
                <div 
                className="w-full"
                style={customWidth.includes(columnName) ? {width: width} : {}}
                >
                <input
                readOnly={readOnly}
                className="border w-full h-full"
                placeholder={`${
                    columnName === 'Amount' ? '$0.00' : '...'
                }`}
                defaultValue={
                    columnName === 'Amount'
                    ? formatAmount(cell.toString())
                    : cell.toString()
                }
                type={'text'}
                name={columnName}
                onBlur={(e) => {
                    if (e.target.value.toString() !== prevValue.toString()) {
                        handleUpdate(
                            parseInt(rowNumber),
                            columnNumber,
                            e.target.value,
                        )
                    }
                }}
                />
        </div>

            )

    // )
}
export default TextInput